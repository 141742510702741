import React, { useState } from "react";
import qs from "query-string";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import moment from "moment";
import { toast } from 'react-toastify'; 
import toastError from '../../errors/toastError';
import 'react-toastify/dist/ReactToastify.css';

const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/login.png`;

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(to right, #e2e2e2, #c9d6ff)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: theme.spacing(2),
  },
  container: {
    backgroundColor: "#fff",
    borderRadius: "30px",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.35)",
    width: "380px",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    "@media (max-width: 600px)": {
      flexDirection: "column", 
      width: "98%", 
      maxWidth: "400px",
      height: "auto", 
      margin: "0 auto", 
    },
  },
  logo: {
    height: "80px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "10px",
   fontWeight: 'bold',
  },
  description: {
    fontSize: "12px",
    marginBottom: "20px",
    color: "#333",
  },
  // input: {
  //   backgroundColor: "#eee",
  //   border: "2px solid black",
  //   margin: "8px 0",
  //   padding: "10px 15px",
  //   fontSize: "13px",
  //   borderRadius: "8px",
  //   width: "100%",
  //   outline: "none",
  //   transition: "border-color 0.3s ease",
  // // },
  // inputFocus: {
  //   borderColor: "#09C184",
  // },

  button: {
    backgroundColor: "#09C184",
    color: "#fff",
    fontSize: "12px",
    padding: "10px 45px",
    border: "none",
    borderRadius: "8px",
    fontWeight: 600,
    marginTop: "10px",
    transition: 'none', 
    '&:hover': {
      backgroundColor: '#09C184', 
    },
    '&:active': {
      backgroundColor: '#09C184', 
    },
  },
  signupMessage: {
    marginTop: "20px",
    fontSize: "13px",
    
  },
  signupLink: {
    color: "#333",
    textDecoration: "none",
    fontWeight: "bold",
    wordSpacing: '15px',
    "&:hover": {
      color: "#09C184", 
      textDecoration: "underline",
    },
  },
}));

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const ForgetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  let companyId = null;
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showResetPasswordButton, setShowResetPasswordButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(""); 

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const toggleAdditionalFields = () => {
    setShowAdditionalFields(!showAdditionalFields);
    if (showAdditionalFields) {
      setShowResetPasswordButton(false);
    } else {
      setShowResetPasswordButton(true);
    }
  };

  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  const initialState = { email: "" };

  const [user] = useState(initialState);
  const dueDate = moment().add(3, "day").format();

  const handleSendEmail = async (values) => {
    const email = values.email;
  
    try {
      const response = await api.post(
        `${process.env.REACT_APP_BACKEND_URL}/forgetpassword/${email}`
      );
      console.log("API Response:", response.data);

      if (response.data.status === 404) {
        toast.error(i18n.t("Endereço e-mail não encontrado"));
        setTimeout(() => history.go(0), 1000)
      } else {
        toast.success(i18n.t("E-mail com código de verificação enviado com sucesso!"));
      }
    } catch (err) {
      console.log("API Error:", err);
      toast.error(i18n.t("Endereço e-mail não encontrado"));
      setTimeout(() => history.go(0), 3000)
    }
  };

  const handleResetPassword = async (values) => {
    const email = values.email;
    const token = values.token;
    const newPassword = values.newPassword;
    const confirmPassword = values.confirmPassword;

    if (newPassword === confirmPassword) {
      try {
        await api.post(
          `${process.env.REACT_APP_BACKEND_URL}/resetpasswords/${email}/${token}/${newPassword}`
        );
        setError(""); // Limpe o erro se não houver erro
        toast.success(i18n.t("Redefinição de senha foi concluído com sucesso"));
        history.push("/login");
      } catch (err) {
        console.log(err);
        toastError(i18n.t("Erro durante o processo de redefinição de senha"))
      }
    }
  };

  const isResetPasswordButtonClicked = showResetPasswordButton;
  const UserSchema = Yup.object().shape({
    email: Yup.string().email(i18n.t("Email inválido")).required(i18n.t("Campo obrigatório")),
    newPassword: isResetPasswordButtonClicked
      ? Yup.string()
          .required(i18n.t("Campo obrigatório"))
          .matches(
            passwordRegex,
            i18n.t("Sua senha precisa ter no mínimo 8 caracteres, sendo uma letra maiúscula, uma minúscula e um número")
          )
      : Yup.string(), // Sem validação se não for redefinição de senha
    confirmPassword: Yup.string().when("newPassword", {
      is: (newPassword) => isResetPasswordButtonClicked && newPassword,
      then: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], i18n.t("As senhas que você digitou não correspondem"))
        .required(i18n.t("Campo obrigatório")),
      otherwise: Yup.string(), // Sem validação se não for redefinição de senha
    }),
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img
          className={classes.logo}
          src={logo}
          alt="Logo"
        />
        <Typography component="h1" variant="h5" className={classes.title}>
          {i18n.t("Redefinir Senha")}
        </Typography>
        <Typography className={classes.description}>
          {i18n.t("Insira seu email para redefinir sua senha")}
        </Typography>
        <Formik
          initialValues={{
            email: "",
            token: "",
            newPassword: "",
            confirmPassword: "",
          }}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              if (showResetPasswordButton) {
                handleResetPassword(values);
              } else {
                handleSendEmail(values);
              }
              actions.setSubmitting(false);
              toggleAdditionalFields();
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    id="email"
                    label={i18n.t("signup.form.email")}
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    autoComplete="email"
                    required
                    className={classes.input}
                    InputProps={{
                      classes: { notchedOutline: classes.inputFocus },
                    }}
                  />
                </Grid>
                {showAdditionalFields && (
                  <>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="token"
                        label={i18n.t("Código de Verificação")}
                        name="token"
                        error={touched.token && Boolean(errors.token)}
                        helperText={touched.token && errors.token}
                        autoComplete="off"
                        required
                        className={classes.input}
                        InputProps={{
                          classes: { notchedOutline: classes.inputFocus },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="newPassword"
                        label={i18n.t("Informe sua nova senha")}
                        name="newPassword"
                        type={showPassword ? "text" : "password"}
                        error={touched.newPassword && Boolean(errors.newPassword)}
                        helperText={touched.newPassword && errors.newPassword}
                        autoComplete="off"
                        required
                        className={classes.input}
                        InputProps={{
                          classes: { notchedOutline: classes.inputFocus },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="confirmPassword"
                        label={i18n.t("Confirme sua nova senha")}
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        autoComplete="off"
                        required
                        className={classes.input}
                        InputProps={{
                          classes: { notchedOutline: classes.inputFocus },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleConfirmPasswordVisibility}
                                edge="end"
                              >
                                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
                disabled={isSubmitting}
              >
                {showResetPasswordButton ? i18n.t("Redefinir Senha") : i18n.t("ENVIAR E-MAIL")}
              </Button>
            </Form>
          )}
        </Formik>
        <Typography className={classes.signupMessage}>
          {i18n.t("Não tem uma conta?")}
          <RouterLink to="/signup" className={classes.signupLink}>
            {i18n.t("Cadastre-se")}
          </RouterLink>
        </Typography>
      </div>
    </div>
  );
};

export default ForgetPassword;



// // import React, { useState } from "react";
// // import qs from "query-string";
// // import IconButton from "@material-ui/core/IconButton";
// // import VisibilityIcon from "@material-ui/icons/Visibility";
// // import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// // import InputAdornment from "@material-ui/core/InputAdornment";
// // import * as Yup from "yup";
// // import { useHistory } from "react-router-dom";
// // import { Link as RouterLink } from "react-router-dom";
// // import { Formik, Form, Field } from "formik";
// // import Button from "@material-ui/core/Button";
// // import CssBaseline from "@material-ui/core/CssBaseline";
// // import TextField from "@material-ui/core/TextField";
// // import Link from "@material-ui/core/Link";
// // import Grid from "@material-ui/core/Grid";
// // import Box from "@material-ui/core/Box";
// // import Typography from "@material-ui/core/Typography";
// // import { makeStyles } from "@material-ui/core/styles";
// // import Container from "@material-ui/core/Container";
// // import api from "../../services/api";
// // import { i18n } from "../../translate/i18n";
// // import moment from "moment";
// // import { toast } from 'react-toastify'; 
// // import toastError from '../../errors/toastError';
// // import 'react-toastify/dist/ReactToastify.css';
// // import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// // const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/login.png`;
// // const useStyles = makeStyles((theme) => ({
// //   root: {
// //     width: "100vw",
// //     height: "100vh",
// //     background: "black", //Cor de fundo
// //     backgroundRepeat: "no-repeat",
// //     backgroundSize: "100% 100%",
// //     backgroundPosition: "center",
// //     display: "flex",
// //     flexDirection: "column",
// //     alignItems: "center",
// //     justifyContent: "center",
// //     textAlign: "center",
// //   },
// //   paper: {
// //     backgroundColor: "white",
// //     display: "flex",
// //     flexDirection: "column",
// //     alignItems: "center",
// //     padding: "55px 30px",
// //     borderRadius: "12.5px",
// //   },
// //   avatar: {
// //     margin: theme.spacing(1),
// //     backgroundColor: theme.palette.secondary.main,
// //   },
// //   form: {
// //     width: "100%", // Fix IE 11 issue.
// //     marginTop: theme.spacing(1),
// //   },
// //   submit: {
// //     margin: theme.spacing(3, 0, 2),
// //   },
// //   powered: {
// //     color: "white",
// //   },
// // }));

// // const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

// // const ForgetPassword = () => {
// //   const classes = useStyles();
// //   const history = useHistory();
// //   let companyId = null;
// //   const [showAdditionalFields, setShowAdditionalFields] = useState(false);
// //   const [showResetPasswordButton, setShowResetPasswordButton] = useState(false);
// //   const [showPassword, setShowPassword] = useState(false);
// //   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
// //   const [error, setError] = useState(""); // Estado para mensagens de erro

// //   const togglePasswordVisibility = () => {
// //     setShowPassword(!showPassword);
// //   };

// //   const toggleConfirmPasswordVisibility = () => {
// //     setShowConfirmPassword(!showConfirmPassword);
// //   };

// //   const toggleAdditionalFields = () => {
// //     setShowAdditionalFields(!showAdditionalFields);
// //     if (showAdditionalFields) {
// //       setShowResetPasswordButton(false);
// //     } else {
// //       setShowResetPasswordButton(true);
// //     }
// //   };

// //   const params = qs.parse(window.location.search);
// //   if (params.companyId !== undefined) {
// //     companyId = params.companyId;
// //   }

// //   const initialState = { email: "" };

// //   const [user] = useState(initialState);
// //   const dueDate = moment().add(3, "day").format();

// // const handleSendEmail = async (values) => {
// //   const email = values.email;
  
// //   try {
// //     const response = await api.post(
// //       `${process.env.REACT_APP_BACKEND_URL}/forgetpassword/${email}`
// //     );
// //     console.log("API Response:", response.data);

// //     if (response.data.status === 404) {
// //       toast.error(i18n.t("forgotPassword.toasts.emailNotFound"));
// //       setTimeout(() => history.go(0), 1000)
// //     } else {
// //       toast.success(i18n.t("forgotPassword.toasts.emailSent"));
// //     }
// //   } catch (err) {
// //     console.log("API Error:", err);
// //     toast.error(i18n.t("forgotPassword.toasts.emailNotFound"));
// //     setTimeout(() => history.go(0), 3000)
// //   }
// // };

// //   const handleResetPassword = async (values) => {
// //     const email = values.email;
// //     const token = values.token;
// //     const newPassword = values.newPassword;
// //     const confirmPassword = values.confirmPassword;

// //     if (newPassword === confirmPassword) {
// //       try {
// //         await api.post(
// //           `${process.env.REACT_APP_BACKEND_URL}/resetpasswords/${email}/${token}/${newPassword}`
// //         );
// //         setError(""); // Limpe o erro se não houver erro
// //         toast.success(i18n.t("forgotPassword.toasts.passwordResetDone"));
// //         history.push("/login");
// //       } catch (err) {
// //         console.log(err);
// //         toastError(i18n.t("forgotPassword.toasts.passwordResetFail"))
// //       }
// //     }
// //   };

// //   const isResetPasswordButtonClicked = showResetPasswordButton;
// //   const UserSchema = Yup.object().shape({
// //     email: Yup.string().email(i18n.t("form.invalidEmail")).required(i18n.t("form.required")),
// //     newPassword: isResetPasswordButtonClicked
// //       ? Yup.string()
// //           .required(i18n.t("form.required"))
// //           .matches(
// //             passwordRegex,
// //             i18n.t("forgotPassword.form.invalidPassword")
// //           )
// //       : Yup.string(), // Sem validação se não for redefinição de senha
// //     confirmPassword: Yup.string().when("newPassword", {
// //       is: (newPassword) => isResetPasswordButtonClicked && newPassword,
// //       then: Yup.string()
// //         .oneOf([Yup.ref("newPassword"), null], i18n.t("forgotPassword.form.passwordsNotSame"))
// //         .required(i18n.t("form.required")),
// //       otherwise: Yup.string(), // Sem validação se não for redefinição de senha
// //     }),
// //   });

// //   return (
// //     <div className={classes.root}>
// //       <Container component="main" maxWidth="xs">
// //         <CssBaseline />
// //         <div className={classes.paper}>
// //           <div>
// //             <img
// //               style={{ margin: "10px auto", height: "100px", width: "100%" }}
// //               src={logo}
// //               alt="Whats"
// //             />
// //           </div>
// //           <Typography component="h1" variant="h5">
// //             {i18n.t("forgotPassword.title")}
// //           </Typography>
// //           <Formik
// //             initialValues={{
// //               email: "",
// //               token: "",
// //               newPassword: "",
// //               confirmPassword: "",
// //             }}
// //             enableReinitialize={true}
// //             validationSchema={UserSchema}
// //             onSubmit={(values, actions) => {
// //               setTimeout(() => {
// //                 if (showResetPasswordButton) {
// //                   handleResetPassword(values);
// //                 } else {
// //                   handleSendEmail(values);
// //                 }
// //                 actions.setSubmitting(false);
// //                 toggleAdditionalFields();
// //               }, 400);
// //             }}
// //           >
// //             {({ touched, errors, isSubmitting }) => (
// //               <Form className={classes.form}>
// //                 <Grid container spacing={2}>
// //                   <Grid item xs={12}>
// //                     <Field
// //                       as={TextField}
// //                       variant="outlined"
// //                       fullWidth
// //                       id="email"
// //                       label={i18n.t("signup.form.email")}
// //                       name="email"
// //                       error={touched.email && Boolean(errors.email)}
// //                       helperText={touched.email && errors.email}
// //                       autoComplete="email"
// //                       required
// //                     />
// //                   </Grid>
// //                   {showAdditionalFields && (
// //                     <>
// //                       <Grid item xs={12}>
// //                         <Field
// //                           as={TextField}
// //                           variant="outlined"
// //                           fullWidth
// //                           id="token"
// //                           label={i18n.t("forgotPassword.form.verificationCode")}
// //                           name="token"
// //                           error={touched.token && Boolean(errors.token)}
// //                           helperText={touched.token && errors.token}
// //                           autoComplete="off"
// //                           required
// //                         />
// //                       </Grid>
// //                       <Grid item xs={12}>
// //                         <Field
// //                           as={TextField}
// //                           variant="outlined"
// //                           fullWidth
// //                           type={showPassword ? "text" : "password"}
// //                           id="newPassword"
// //                           label={i18n.t("forgotPassword.form.newPassword")}
// //                           name="newPassword"
// //                           error={
// //                             touched.newPassword &&
// //                             Boolean(errors.newPassword)
// //                           }
// //                           helperText={
// //                             touched.newPassword && errors.newPassword
// //                           }
// //                           autoComplete="off"
// //                           required
// //                           InputProps={{
// //                             endAdornment: (
// //                               <InputAdornment position="end">
// //                                 <IconButton
// //                                   onClick={togglePasswordVisibility}
// //                                 >
// //                                   {showPassword ? (
// //                                     <VisibilityIcon />
// //                                   ) : (
// //                                     <VisibilityOffIcon />
// //                                   )}
// //                                 </IconButton>
// //                               </InputAdornment>
// //                             ),
// //                           }}
// //                         />
// //                       </Grid>
// //                       <Grid item xs={12}>
// //                         <Field
// //                           as={TextField}
// //                           variant="outlined"
// //                           fullWidth
// //                           type={showConfirmPassword ? "text" : "password"}
// //                           id="confirmPassword"
// //                           label={i18n.t("forgotPassword.form.confirmPassword")}
// //                           name="confirmPassword"
// //                           error={
// //                             touched.confirmPassword &&
// //                             Boolean(errors.confirmPassword)
// //                           }
// //                           helperText={
// //                             touched.confirmPassword &&
// //                             errors.confirmPassword
// //                           }
// //                           autoComplete="off"
// //                           required
// //                           InputProps={{
// //                             endAdornment: (
// //                               <InputAdornment position="end">
// //                                 <IconButton
// //                                   onClick={toggleConfirmPasswordVisibility}
// //                                 >
// //                                   {showConfirmPassword ? (
// //                                     <VisibilityIcon />
// //                                   ) : (
// //                                     <VisibilityOffIcon />
// //                                   )}
// //                                 </IconButton>
// //                               </InputAdornment>
// //                             ),
// //                           }}
// //                         />
// //                       </Grid>
// //                     </>
// //                   )}
// //                 </Grid>
// //                 {showResetPasswordButton ? (
// //                   <Button
// //                     type="submit"
// //                     fullWidth
// //                     variant="contained"
// //                     color="primary"
// //                     className={classes.submit}
// //                   >
// //                     {i18n.t("forgotPassword.title")}
// //                   </Button>
// //                 ) : (
// //                   <Button
// //                     type="submit"
// //                     fullWidth
// //                     variant="contained"
// //                     color="primary"
// //                     className={classes.submit}
// //                   >
// //                     {i18n.t("forgotPassword.buttons.sendEmail")}
// //                   </Button>
// //                 )}
// //                 <Grid container justifyContent="flex-end">
// //                   <Grid item>
// //                     <Link
// //                       href="#"
// //                       variant="body2"
// //                       component={RouterLink}
// //                       to="/signup"
// //                     >
// //                       {i18n.t("forgotPassword.doYouHaveAccount")}
// //                     </Link>
// //                   </Grid>
// //                 </Grid>
// //                 {error && (
// //                   <Typography variant="body2" color="error">
// //                     {error}
// //                   </Typography>
// //                 )}
// //               </Form>
// //             )}
// //           </Formik>
// //         </div>
// //         <Box mt={5} />
// //       </Container>
// //     </div>
// //   );
// // };

// // export default ForgetPassword;
