import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { versionSystem } from "../../../package.json";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { nomeEmpresa } from "../../../package.json";
import { AuthContext } from "../../context/Auth/AuthContext";

const Copyright = () => {
  return (
    <Typography variant="body2" color="primary" align="center">
      {"Copyright © "}
      <Link color="primary" href="#">
        {nomeEmpresa} - v {versionSystem}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
	"@global": {
	  body: {
		margin: 0,
		padding: 0,
		boxSizing: "border-box",
		fontFamily: "'Montserrat', sans-serif",
		backgroundColor: "#c9d6ff",
		background: "linear-gradient(to right, #e2e2e2, #c9d6ff)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		minHeight: "100vh", 
	  },
	},
  
	container: {
	  backgroundColor: "#fff",
	  borderRadius: "30px",
	  boxShadow: "0 5px 15px rgba(0, 0, 0, 0.35)",
	  display: "flex",
	  flexDirection: "row", 
	  width: "680px", 
	  height: "450px", 
	  maxWidth: "100%",
	  overflow: "hidden",
	  "@media (max-width: 600px)": {
		flexDirection: "column", 
		width: "90vw", 
		maxWidth: "100vw", 
		height: "auto", 
		margin: "0 auto", 
	  },
	  "@media (min-width: 768px) and (max-width: 1024px)": {
		
		width: "85vw", 
		height: "auto",
		margin: "0 auto", 
	  },
	},
  
	formContainer: {
	  width: "50%",
	  padding: "40px",
	  display: "flex",
	  alignItems: "center",
	  justifyContent: "center",
	  flexDirection: "column",
	  fontFamily: "'Montserrat', sans-serif",
	  "@media (max-width: 600px)": {
		width: "100%", 
		padding: "20px", 
	  },
	},
  
	logo: {
	  height: "70px",
	  marginBottom: "20px",
	  "@media (max-width: 600px)": {
		height: "50px", 
	  },
	},
  
	button: {
	  backgroundColor: "#09C184",
	  color: "#fff",
	  fontSize: "14px",
	  padding: "10px 45px",
	  border: "none",
	  borderRadius: "8px",
	  fontWeight: 600,
	  textTransform: "uppercase",
	  marginTop: "20px",
	  cursor: "pointer",
	  display: "block",
	  textAlign: "center",
	  "@media (max-width: 600px)": {
		fontSize: "12px", 
		padding: "10px 25px", 
	  },
	},
  
	buttonWrapper: {
	  display: "flex",
	  justifyContent: "center",
	  width: "100%",
	},
  
	toggleContainer: {
	  width: "50%", 
	  backgroundColor: "#25d366",
	  background: "linear-gradient(to right, #128c7e, #25d366)",
	  color: "#fff",
	  display: "flex",
	  alignItems: "center",
	  justifyContent: "center",
	  padding: "40px",
	  "@media (max-width: 600px)": {
		display: "none", 
	  },
	},
  
	togglePanel: {
	  textAlign: "center",
	},
  
	togglePanelText: {
	  fontSize: "16px",
	  lineHeight: "24px",
	  "@media (max-width: 600px)": {
		fontSize: "14px", 
	  },
	},
  
	link: {
	  color: "#333",
	  fontSize: "13px",
	  textAlign: "center",
	  textDecoration: "none",
	  margin: "15px 0 10px",
	  "&:hover": {
		color: "#09C184",
		textDecoration: "underline",
	  },
	  "@media (max-width: 600px)": {
		fontSize: "12px", 
	  },
	},
  
	forgotPasswordLink: {
	  color: "#333",
	  fontSize: "13px",
	  textDecoration: "none",
	  "&:hover": {
		color: "#09C184",
	  },
	},
  }));
  
const Login = () => {
	const classes = useStyles();
	const [user, setUser] = useState({ email: "", password: "" });
	const { handleLogin } = useContext(AuthContext);
	const [viewregister, setViewregister] = useState("disabled");
  
	const handleChangeInput = (e) => {
	  setUser({ ...user, [e.target.name]: e.target.value });
	};
  
	useEffect(() => {
	  fetchViewRegister();
	}, []);
  
	const fetchViewRegister = async () => {
	  try {
		const responsev = await api.get("/settings/viewregister");
		const viewregisterX = responsev?.data?.value;
		setViewregister(viewregisterX);
	  } catch (error) {
		console.error("Error retrieving viewregister", error);
	  }
	};
  
	const handleSubmit = (e) => {
	  e.preventDefault();
	  handleLogin(user);
	};
  
	const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/login.png`;
	const randomValue = Math.random(); 
	const logoWithRandom = `${logo}?r=${randomValue}`;
  
	return (
	  <div>
		<div className={classes.container}>
		  <CssBaseline />
		  {/* Login Form */}
		  <div className={classes.formContainer}>
			<img className={classes.logo} src={logoWithRandom} alt="Logo" />
			<form onSubmit={handleSubmit} style={{ width: "100%" }}>
			  <Typography variant="h5" align="center" gutterBottom>
				Entrar
			  </Typography>
			  <TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				id="email"
				label="Email"
				name="email"
				value={user.email}
				onChange={handleChangeInput}
				autoComplete="email"
				autoFocus
			  />
			  <TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				name="password"
				label="Senha"
				type="password"
				id="password"
				value={user.password}
				onChange={handleChangeInput}
				autoComplete="current-password"
			  />
			  <Grid container justify="flex-end">
				<Grid item xs={6} style={{ textAlign: "right" }}>
				  <Link
					className={classes.forgotPasswordLink}
					component={RouterLink}
					to="/forgetpsw"
					variant="body2"
				  >
					Esqueceu sua senha?
				  </Link>
				</Grid>
			  </Grid>
			  <div className={classes.buttonWrapper}>
				<button type="submit" className={classes.button}>
				  Entrar
				</button>
			  </div>
			  {/* Add sign-up link */}
			  {viewregister === "enabled" && (
				<Grid container justify="center">
				  <Grid item>
					<Link
					  className={classes.link}
					  component={RouterLink}
					  to="/signup"
					  style={{ marginTop: "20px", display: "block", textAlign: "center" }}
					>
					  Cadastre-se, agora mesmo!
					</Link>
				  </Grid>
				</Grid>
			  )}
			</form>
		  </div>
  
		  {/* Right side green panel */}
		  <div className={classes.toggleContainer}>
			<div className={classes.togglePanel}>
			  <Typography variant="h4">Olá!</Typography>
			  <Typography className={classes.togglePanelText}>
				Por favor, insira seus dados para acessar a plataforma.
			  </Typography>
			</div>
		  </div>
		</div>
  
		{/* Copyright at the bottom */}
		<div style={{ marginTop: "20px", textAlign: "center" }}>
		  <Copyright />
		</div>
	  </div>
	);
  };
  
  export default Login;
  




// import React, { useState, useContext, useEffect } from "react";
// import { Link as RouterLink } from "react-router-dom";

// import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
// import Link from "@material-ui/core/Link";
// import Grid from "@material-ui/core/Grid"; 
// import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
// import { versionSystem } from "../../../package.json";
// import { i18n } from "../../translate/i18n";
// import api from "../../services/api";
// import { nomeEmpresa } from "../../../package.json";
// import { AuthContext } from "../../context/Auth/AuthContext";
// //import logo from "../../assets/logo.png";


// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="primary" align="center">
// 			{"Copyright © "}
//  			<Link color="primary" href="#">
//  				{ nomeEmpresa } - v { versionSystem }
//  			</Link>{" "}
//  			{new Date().getFullYear()}
//  			{"."}
//  		</Typography>
//  	);
//  };

// const useStyles = makeStyles(theme => ({
// 	root: {
// 		width: "100vw",
// 		height: "100vh",
// 		backgroundColor: "#fff",
// 		backgroundRepeat: "no-repeat",
// 		backgroundSize: "100% 100%",
// 		backgroundPosition: "center",
// 		display: "flex",
// 		flexDirection: "column",
// 		alignItems: "center",
// 		justifyContent: "center",
// 		textAlign: "center",
// 	},
// 	paper: {
// 		backgroundColor: theme.palette.login, //DARK MODE Multiconversa//
// 		display: "flex",
// 		flexDirection: "column",
// 		alignItems: "center",
// 		padding: "55px 30px",
// 		borderRadius: "12.5px",
// 	},
// 	avatar: {
// 		margin: theme.spacing(1),  
// 		backgroundColor: theme.palette.secondary.main,
// 	},
// 	form: {
// 		width: "100%", // Fix IE 11 issue.
// 		marginTop: theme.spacing(1),
// 	},
// 	submit: {
// 		margin: theme.spacing(3, 0, 2),
// 	},
// 	powered: {
// 		color: "white"
// 	}
// }));

// const Login = () => {
// 	const classes = useStyles();

// 	const [user, setUser] = useState({ email: "", password: "" });

// 	const { handleLogin } = useContext(AuthContext);
// 	const [viewregister, setviewregister] = useState('disabled');

// 	const handleChangeInput = e => {
// 		setUser({ ...user, [e.target.name]: e.target.value });
// 	};
	
// 	    useEffect(() => {
//     	fetchviewregister();
//   	}, []);
	
// 		const fetchviewregister = async () => {
  
 
//     try {
//     	const responsev = await api.get("/settings/viewregister");
//       	const viewregisterX = responsev?.data?.value;
//       	// console.log(viewregisterX);
//       	setviewregister(viewregisterX);
//     	} catch (error) {
//     		console.error('Error retrieving viewregister', error);
//     	}
//   	};


// 	const handlSubmit = e => {
// 		e.preventDefault();
// 		handleLogin(user);
// 	};
	
// 	const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/login.png`;
//     const randomValue = Math.random(); // Generate a random number
  
//     const logoWithRandom = `${logo}?r=${randomValue}`;

// 	return (
// 		<div className={classes.root}>
// 		<Container component="main" maxWidth="xs">
// 			<CssBaseline/>
// 			<div className={classes.paper}>
// 				<div>
// 					<img style={{ margin: "0 auto", width: "100%" }} src={logoWithRandom} alt={`${process.env.REACT_APP_NAME_SYSTEM}`} />
// 				</div>
// 				{/*<Typography component="h1" variant="h5">
// 					{i18n.t("login.title")}
// 				</Typography>*/}
// 				<form className={classes.form} noValidate onSubmit={handlSubmit}>
// 					<TextField
// 						variant="outlined"
// 						margin="normal"
// 						required
// 						fullWidth
// 						id="email"
// 						label={i18n.t("login.form.email")}
// 						name="email"
// 						value={user.email}
// 						onChange={handleChangeInput}
// 						autoComplete="email"
// 						autoFocus
// 					/>
// 					<TextField
// 						variant="outlined"
// 						margin="normal"
// 						required
// 						fullWidth
// 						name="password"
// 						label={i18n.t("login.form.password")}
// 						type="password"
// 						id="password"
// 						value={user.password}
// 						onChange={handleChangeInput}
// 						autoComplete="current-password"
// 					/>
					
// 					<Grid container justify="flex-end">
// 					  <Grid item xs={6} style={{ textAlign: "right" }}>
// 						<Link component={RouterLink} to="/forgetpsw" variant="body2">
// 						  {i18n.t("login.forgotPassword")}
// 						</Link>
// 					  </Grid>
// 					</Grid>
				
					
// 					<Button
// 						type="submit"
// 						fullWidth
// 						variant="contained"
// 						color="primary"
// 						className={classes.submit}
// 					>
// 						{i18n.t("login.buttons.submit")}
// 					</Button>
//                     {viewregister === "enabled" && (
//                     <>
// 					<Grid container>
// 						<Grid item>
// 							<Link
// 								href="#"
// 								variant="body2"
// 								component={RouterLink}
// 								to="/signup"
// 							>
// 								{i18n.t("login.buttons.register")}
// 							</Link>
// 						</Grid>
// 					</Grid>
//                     </>
//                     )}
				
					
// 				</form>
			
// 			</div>
// 			<Box mt={8}><Copyright /></Box>
// 		</Container>
// 		</div>
// 	);
// };

// export default Login;
